//
// Copyright 2023 DXOS.org
//

import { SPACE_PLUGIN } from './meta';
import { CollectionType } from './types';

export default [
  {
    'en-US': {
      [CollectionType.typename]: {
        'typename label': 'Collection',
        'object name placeholder': 'New collection',
      },
      [SPACE_PLUGIN]: {
        'plugin name': 'Spaces',
        'first run message': 'Nothing selected.',
        'add space label': 'Create or join a space',
        'create space label': 'Create space',
        'join space label': 'Join space',
        'empty space message': 'No documents',
        'empty tree message': 'No spaces',
        'unnamed space label': 'New space',
        'closed space label': 'Closed',
        'loading space label': 'Loading space…',
        'lock space label': 'Lock space',
        'unlock space label': 'Unlock space',
        'rename space label': 'Rename space',
        'active space label': 'Active space:',
        'space name label': 'Space name',
        'object name label': 'Object name',
        'close space label': 'Close space',
        'open space label': 'Open space',
        'export data label': 'Export data',
        'import data label': 'Import data',
        'migrate space label': 'Migrate space',
        'share space': 'Share space',
        'save space to disk label': 'Save to disk',
        'load space from disk label': 'Load from disk',
        'confirm restore title': 'Overwrite files in this space?',
        'confirm restore body': 'Restoring from a backup will overwrite the contents of any documents that match.',
        'upload file message': 'Drag file here or click to browse',
        'object placeholder': 'Type a title here…',
        'personal space label': 'Personal Space',
        'spaces label': 'Spaces',
        'show hidden spaces label': 'Show closed spaces',
        'save files to directory label': 'Save files to disk',
        'select path label': 'Select path',
        'rename collection label': 'Rename collection',
        'rename object label': 'Rename item',
        'duplicate object label': 'Duplicate item',
        'delete collection label': 'Delete collection',
        'delete object label': 'Delete item',
        'collection deleted label': 'Collection deleted',
        'object deleted label': 'Item deleted',
        'objects deleted label': 'Items deleted',
        'go to object label': 'Open item',
        'found object label': 'Ready.',
        'found object description': 'The requested object is now available.',
        'waiting for object label': 'Loading…',
        'waiting for object description': 'The requested object is still being synchronized.',
        'object not found label': 'Nothing found.',
        'object not found description':
          'The requested object has not been found yet. Ensure there are enough peers online in the space with an updated copy.',
        'missing object message': 'Object not available.',
        'missing object description':
          'The requested object has not been found yet. Ensure there are enough peers online in the space with an updated copy.',
        'create object in space label': 'Add to space',
        'create object in collection label': 'Add to collection',
        'share space label': 'Share',
        'space members label': 'Space members',
        'active space members heading': 'Online ({{count}})',
        'inactive space members heading': 'Offline ({{count}})',
        'presence label_zero': 'No other viewers',
        'presence label_one': '1 other viewer',
        'presence label_other': '{{count}} other viewers',
        'persisted locally label': 'Changes saved locally.',
        'persisted locally message': 'All changes have been saved to disk on this device.',
        'persistence pending label': 'Saving…',
        'persistence error label': 'Error saving changes.',
        'more actions label': 'More actions',
        'invitations heading': 'Invitations',
        'keyshortcuts label': 'Keyboard shortcuts',
        'menu footer label': 'Details',
        'location label': 'Located in',
        'space limit label': 'Space Limit Reached',
        'space limit description':
          'This space has reached the maximum number of objects for the beta. This limit includes deleted objects currently, but those can be permanently removed with the cleanup action.',
        'space limit close label': 'Close',
        'remove deleted objects label': 'Cleanup',
        'remove deleted objects alt': 'Permanently remove deleted objects to free up space.',
        'copy link label': 'Copy link',
        'default on space create label': 'On space create',
        'sync status title': 'Sync status',
        'dismiss label': 'Dismiss',
        'join success label': 'Successfully joined space',
        'name label': 'Name',
        'name placeholder': 'Name',
        'unnamed object settings label': 'Settings',
        'edge replication label': 'Enable EDGE Replication',
        'saving locally label': 'Writing to disk',
        'downloading label': 'Replicating from peers',
        'uploading label': 'Replicating to peers',
        'offline persisted label': 'Saved to disk (offline)',
        'remote synced label': 'Synced with peers',
        'open settings panel label': 'Show Settings',
        'settings panel label': 'Settings',
        'open space settings label': 'Space Settings',
        'members tab label': 'Members',
        'settings tab label': 'Settings',
        'syncing label': 'Space syncing',
        'show all label': 'Show all',
        'no sync status label': 'No space with missing objects.',
        'create space dialog title': 'Create Space',
        'create object dialog title': 'Create Object',
        'space input placeholder': 'Select space',
        'schema input placeholder': 'Select object type',
        'creating object type label': 'Type',
        'creating in space label': 'Location',
        'creating in collection label': 'In Collection',
        'clear input label': 'Clear',
        'expose object label': 'Expose in navtree',
        'advanced settings label': 'Advanced',
        'foreign keys': 'Foreign Keys',
        'add key': 'Add Key',
        'icon label': 'Icon',
        'hue label': 'Color',
      },
    },
  },
];
